<script>
export default {};
</script>
<template>
  <div class="account-pages my-5 pt-5">
    <BContainer>
      <BRow>
        <BCol lg="12">
          <div class="text-center mb-5">
            <h1 class="display-2 fw-medium">
              4<i class="bx bx-buoy bx-spin text-primary display-3"></i>3
            </h1>
            <h4 class="text-uppercase">Sorry, you dont permission!</h4>
            <div class="mt-5 text-center">
              <BButton variant="primary" @click="$router.go(-1)">
                Back to Previous Page
              </BButton>
            </div>
          </div>
        </BCol>
      </BRow>
      <BRow class="justify-content-center">
        <BCol md="8" xl="6">
          <div>
            <img src="@/assets/images/error-img.png" alt class="img-fluid" />
          </div>
        </BCol>
      </BRow>
    </BContainer>
  </div>
</template>
